import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import authService from "src/services/userService";

const initialState = {
  user: null,
  accessToken: localStorage.getItem('bingo_access_token') || null,
  login_error: '',
  isAdmin:false,
  isSuper:false,
  isAgent:false,
  user_is_loading:false,
  all_user_list: [],
  agent_lists:[],
  isAuthenticated: false,
  pwd_change_status : null,
  roles:[],
  current_company:null,
  transferDetail:null,
  transferDetailError:null,
  user_name_error:'',
  phone_number_error:'',
  play_percentage:localStorage.getItem('game_type'),
  bet_amount:null
}

export const login = createAsyncThunk(
  "auth/login",
  async ({ userName, password }, thunkAPI) => {
    try {
      const userData = { userName, password };
      const data = await authService.login(userData);
      return data;
    } catch (error) {
      //console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const get_user_info = createAsyncThunk(
  "auth/get_user_info",
  async(thunkAPI)=>{
    try {
      const data = await authService.get_user_info();
      return data;
    } catch (error) {
      //console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (thunkAPI,{dispatch}) => {
  try {
    await authService.logout(dispatch);
  } catch (error) {
    //console.log(error);
  }
});

export const getAllUsers = createAsyncThunk("users/getAllUsers",
  async (thunkAPI) => {
    try {
      const data = await authService.getAllUsers();
      return { users: data.data };
    } catch (error) {
      //console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAgentUsers = createAsyncThunk("users/getAgentUsers",
  async (thunkAPI) => {
    try {
      const data = await authService.getAgentUsers();
      return { users: data.data };
    } catch (error) {
      //console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createUser = createAsyncThunk("users/createUser",
  async (user_data, thunkAPI) => {
    try {
      const created_user = await authService.createUser(user_data);
      return created_user.data;
    } catch (error) {
      //console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserInfoByPhone = createAsyncThunk("users/getUserInfoByPhone",
  async (phone, thunkAPI) => {
    try {
      const user = await authService.getUserInfoByPhone(phone);
      return user;
    } catch (error) {
      //console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk("users/updateUser",
  async (user_info, thunkAPI) => {
    try {
      const {id,userData} = user_info;
      const updated_user = await authService.updateUser(id,userData);
      return updated_user.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const passwordChange = createAsyncThunk('users/changePassword',
  async(pwd_change_obj,thunkAPI) =>{
    try {
      //console.log('slice method start');
      const result = await authService.change_password(pwd_change_obj);
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRoles = createAsyncThunk('users/getRoles',
  async(thunkAPI)=>{
    try {
      const result = await authService.getRoles();
      //console.log(result);
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
)

export const getPlayPercentage = createAsyncThunk('users/getPlayPercentage',
  async(thunkAPI)=>{
    try {
      
      const result = await authService.get_play_percentage();
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
)

export const getBetAmount = createAsyncThunk('users/getBetAmount',
  async(thunkAPI)=>{
    try {
      
      const result = await authService.get_bet_amount();
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
)


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserNameError(state,action){
      state.user_name_error=action.payload.message
    },
    setPhoneNumberError(state,action){
      
      state.phone_number_error=action.payload.message
    }
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      //console.log('loggin successfull ' + state.accessToken);
      //console.log(action.payload.u_payload.user.role);
      state.isAuthenticated = true;
      state.user = action.payload.u_payload.user;
      state.accessToken = action.payload.accessToken;
      state.current_company=action.payload.u_payload.company;

      state.isAdmin = action.payload.u_payload.user.role === 'Admin';
      state.isSuper = action.payload.u_payload.user.role === 'super';
      state.isAgent = action.payload.u_payload.user.role === 'Agent' || action.payload.u_payload.user.role === 'SubAgent';
      localStorage.setItem('bingo_access_token',state.accessToken);
      state.user_is_loading = false;
    },
    [login.rejected]: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.user_is_loading = false;
      
      state.login_error = action.payload.errors;
      //console.log(action.payload.errors);
    },
    [login.pending]:(state,action)=>{
      state.user_is_loading = true;
    },
    [logout.fulfilled]:(state,action) =>{
      // state.isAuthenticated = false;
      // state.user = null;
      // state.accessToken = null;
      // state.roles = [];
      // state = initialState;
      // console.log(state.roles);
    },
    [get_user_info.fulfilled]:(state,action)=>{
      state.user = action.payload.user;
      state.isAdmin = action.payload.user.role === 'Admin';
      state.isSuper = action.payload.user.role === 'super';
      state.isAgent = action.payload.user.role === 'Agent' || action.payload.user.role === 'SubAgent';
      state.current_company = action.payload.company;
      state.user_is_loading = false;
    },
    [get_user_info.rejected]:(state,action)=>{
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      localStorage.removeItem('bingo_access_token');
      state.user_is_loading = false;
    },
    [get_user_info.pending]:(state,action)=>{
      state.user_is_loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      //console.log('fullfiled state here')
      state.all_user_list = action.payload.users;
    },
    [getAllUsers.rejected]: (state, action) => {
      //console.log('rejected state here')
      state.all_user_list = []
    },
    [createUser.fulfilled]: (state, action) => {
      //console.log(action.payload);
      state.all_user_list.push(action.payload);
      state.user_name_error = ''
      
      state.phone_number_error = '';
      
    },
    [createUser.pending]: (state, action) => {
      //console.log('user is pending :::');
    },
    [createUser.rejected]: (state, action) => {
      //console.log('saved user fetch rejected');
      //console.log(action.payload);

      state.user_name_error = action.payload;
      state.phone_number_error = action.payload;
    },
    [updateUser.fulfilled]:(state,action)=>{
      
      state.all_user_list = state.all_user_list.filter(t=>t._id !== action.payload._id);
      state.all_user_list.push(action.payload);
      state.user_name_error = ''
     
      state.phone_number_error = '';
      
    },
    [updateUser.pending]:(state,action)=>{
      //console.log('waiting ...')
    },
    [updateUser.rejected]:(state,action)=>{
      //console.log(action.payload.message);
      state.user_name_error = action.payload;
      
      state.phone_number_error = action.payload.message;
      
    },
    [passwordChange.fulfilled]:(state,action)=>{
      if(!action.payload.data.error)
        state.pwd_change_status = "password updated successfully";
      else
        state.pwd_change_status = action.payload.data.error;

        //console.log(action.payload.data.error);
    },
    [passwordChange.rejected]:(state,action)=>{
      state.pwd_change_status = action.payload.data.error;
    },
    [getRoles.fulfilled]:(state,action)=>{
      //console.log(action.payload);
      state.roles = action.payload;
    },
    [getRoles.rejected]:(state,action)=>{
      state.roles = []
    },
    [getPlayPercentage.fulfilled]:(state,action)=>{
      if(action.payload.data && action.payload.data.play_percent != null){
        state.play_percentage = action.payload.data.play_percent;
      }else{
        state.play_percentage = null;
      }
    },
    [getPlayPercentage.rejected]:(state,action)=>{
      state.play_percentage = null;
      //console.log('rejected')
      
    },

    [getBetAmount.fulfilled]:(state,action)=>{
      if(action.payload.data && action.payload.data.bet_amount != null){
        state.bet_amount = action.payload.data.bet_amount;
      }else{
        state.bet_amount = null;
      }
      //console.log(state.bet_amount)
    },
    [getBetAmount.rejected]:(state,action)=>{
      state.bet_amount = null;
      //console.log('rejected')
      //console.log(state.bet_amount);
    },
    [getAgentUsers.fulfilled]:(state,action)=>{
      state.agent_lists = action.payload.users;
      //console.log(state.agent_lists);
    },
    [getAgentUsers.rejected]:(state,action)=>{
      state.agent_lists=[];
    },
    [getUserInfoByPhone.fulfilled]:(state,action)=>{
      //console.log(action.payload);
      state.transferDetail = action.payload.data;
      state.transferDetailError = null;
    },
    [getUserInfoByPhone.pending]:(state,action)=>{
      //console.log('checing ....')
      state.transferDetailError = 'checking ...';
    },
    [getUserInfoByPhone.rejected]:(state,action)=>{
      //console.log(action.payload);
      state.transferDetailError = action.payload;
      state.transferDetail = null;
    }
  }
});

export const {setUserNameError,setPhoneNumberError} = userSlice.actions;
const { reducer } = userSlice;
export default reducer;