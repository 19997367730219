import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

//axios

import { useDispatch,useSelector } from 'react-redux';
import { login } from 'src/redux/slices/userSlice';


// ----------------------------------------------------------------------

export default function LoginForm() {

  const [userName,setUserName] = useState('');
  const [password,setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login_error = useSelector((state)=>state.auth.login_error);

  const handleClick = async () => {
    //login clicked
    const userData = {
        userName,
        password
    };
    await dispatch(login(userData)).then(()=>{
      navigate('/dashboard/app');
    });
  };

  return (
    <>
      <Stack spacing={3}>
        
        <TextField name="userName" label="User Name" onChange={(e)=> setUserName(e.target.value)}/>

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=> setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
        <p style={{color:'red',fontSize:'12px'}}>{login_error}</p>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
