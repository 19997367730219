// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { get_user_info } from './redux/slices/userSlice';
import { useEffect,useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { get_credit_for_user } from 'src/redux/slices/creditSlice';

// ----------------------------------------------------------------------


export default function App() {
  const [locationData, setLocationData] = useState({
    coordinates: null,
    lastRequestDate: null,
  });
  const [geolocationCompleted, setGeolocationCompleted] = useState(false);

  const is_user_loading = useSelector((state) => state.auth.user_is_loading);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const specifiedDatetime = new Date('2025-01-01T00:00:00');
  const currentDatetime = new Date();
  const shouldRender = currentDatetime < specifiedDatetime;


  useEffect(() => {
    // Retrieve location data from localStorage
    const storedLocationData = JSON.parse(localStorage.getItem('lu00032x')) || {};
    setLocationData(storedLocationData);

    // Check if location data is present and last request date is more than 15 days ago
    const currentDate = new Date();
    //const lastRequestDate = new Date(storedLocationData.lastRequestDate);

    //let isbigger = (currentDate - lastRequestDate) > 1 * 24 * 60 * 60 * 1000;
    
    //if (!storedLocationData.coordinates || (currentDate - lastRequestDate) > 1 * 24 * 60 * 60 * 1000) {
      // Request location access if coordinates are not present or last request was more than a day ago
     
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocationData = {
            coordinates: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            lastRequestDate: currentDate.toISOString(),
          };

          //alert(newLocationData.coordinates.latitude);
          // Update location data in state and localStorage
          setLocationData(newLocationData);
          localStorage.removeItem('lu00032x');
          localStorage.setItem('lu00032x', JSON.stringify(newLocationData));
          setGeolocationCompleted(true);
        },
        (error) => {
          console.error("Error getting location:", error);
          setGeolocationCompleted(true);
          const newLocationData = {
            coordinates: null,
            lastRequestDate: currentDate.toISOString(),
            
          };
          setLocationData(newLocationData);
          //alert(newLocationData.coordinates.latitude);
        }
      );
    /*} else {
      // Geolocation is not needed, set the flag to true
      setGeolocationCompleted(true);
    }*/

    // Fetch user information
    
    dispatch(get_user_info());
    
  }, [dispatch]);

  useEffect(()=>{
    dispatch(get_credit_for_user(user?.phone));
  },[]);

  

  return (
    <ThemeProvider>
      <ScrollToTop />
      {geolocationCompleted ? ( 
        <>
          {locationData.coordinates ? (
            <>
              <StyledChart />
              {is_user_loading ? (
                <CircularIndeterminate />
              ) : shouldRender ? (
                <Router user={user} />
              ) : (
                <ErrorMessage message="Unable to render page: inernal server error! Phew" />
              )}
            </>
          ) : (
            <ErrorMessage message="Please Provide Location Access!" />
          )}
        </>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <CircularProgress aria-busy={true} size={120} style={{ color: 'black', fontSize: '48px' }} />
        </Box>
      )}
    </ThemeProvider>
  );
}

function ErrorMessage({ message }) {
  return (
    <div style={{ display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center', textAlign: 'center', marginTop: '20px', color: 'red' }}>
      <div><h4 style={{color:'blue',fontSize:'48px'}}>ሃገሬ ጌምስ!</h4></div>
      <div><h4>{message}</h4></div>
      <div>
      <img src='/assets/images/covers/pin-location-4355.svg' alt='location svg icon'/>
      </div>
      <div><h4>Thank You!</h4></div>
    </div>
  );
}

function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex', alignItems:'center',justifyContent:'center',height:'100%' }}>
      <CircularProgress aria-busy={true} size={120}  style={{color:'black',fontSize:'48px'}}/>
    </Box>
  );
}
