import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import creditService from "src/services/creditService";

const initialState = {
    credit:null,
    trx_history:[],
    transfer_completed:null,
    transfer_message:null,

    reverse_trx_history_obj:null,
    reverse_msg:null,
}

//reverse

export const get_trx_history_by_id = createAsyncThunk(
    'get_trx_history_by_id',
    async(trx_id,thunkAPI)=>{
        try {
            const result = await creditService.get_trx_history_by_id(trx_id);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const reverse_trx = createAsyncThunk(
    'reverse_trx',
    async(trx_id,thunkAPI)=>{
        try {
            const result = await creditService.reverse_trx(trx_id);
            //console.log(result);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const credit_transfer = createAsyncThunk(
    'creditTransfer',
    async(transferObj,thunkAPI)=>{
        try {
            const result = await creditService.credit_transfer(transferObj);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const get_credit_for_user = createAsyncThunk(
    'getCreditForUser',
    async(user,thunkAPI)=>{
        try {
            
            const result = await creditService.get_credit_for_user(user);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const get_trx_history = createAsyncThunk(
    'getTrxHistory',
    async(reqObj,thunkAPI)=>{
        try {
            const {user,fetchAll} = reqObj;
            const result = await creditService.get_trx_history(user,fetchAll);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const get_trx_history_and_credit = createAsyncThunk(
    'getTrxHistoryAndCredit',
    async(reqObj,thunkAPI)=>{
        try {
            const {user,fetchAll} = reqObj;
            const result = await creditService.get_trx_history_and_credit(user,fetchAll);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const get_trx_history_by_condition = createAsyncThunk(
    'get_trx_history_by_condition',
    async(reqObj,thunkAPI)=>{
        try {
            const result = await creditService.get_trx_history_by_condition(reqObj);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const creditSlice = createSlice({
    name:'credits',
    initialState,
    reducers:{
        resetTransferMessage:(state,action)=>{
            state.transfer_message = null;
        },
        resetReverseMessage:(state,action)=>{
            state.reverse_msg = null;
        }
    },
    extraReducers:{
        [get_trx_history_by_condition.fulfilled]:(state,action)=>{
            state.trx_history = action.payload.data;
        },
        [get_trx_history_by_condition.rejected]:(state,action)=>{

        },
        [get_trx_history_by_condition.pending]:(state,action)=>{
            
        },
        [get_trx_history_and_credit.fulfilled]:(state,action)=>{
            
                state.trx_history = action.payload.data.trxHistory;
                state.credit = action.payload.data.credit;
           
        },
        [get_trx_history_and_credit.rejected]:(state,action)=>{
            //console.log(action.payload);
        },
        [get_trx_history_and_credit.pending]:(state,action)=>{
            
        },
        [get_trx_history.fulfilled]:(state,action)=>{
            //console.log(action.payload);
            state.trx_history = action.payload.data;
           
        },
        [get_trx_history.pending]:(state,action)=>{

        },
        [get_trx_history.rejected]:(state,action)=>{
            //console.log(action.payload);
        },
        [get_credit_for_user.fulfilled]:(state,action)=>{
            //console.log(action.payload);
            state.credit = action.payload?.data; 
        },
        [get_credit_for_user.pending]:(state,action)=>{
           
        },
        [get_credit_for_user.rejected]:(state,action)=>{
            //console.log(action.payload);
        },
        [credit_transfer.fulfilled]:(state,action)=>{
           //sender_trx_history
           //sender_credit
           //receiver_credit
           state.credit = action.payload.data.sender_credit;
           //state.trx_history.push(action.payload.data.sender_trx_history);
           state.trx_history.unshift(action.payload.data.sender_trx_history);
           state.transfer_completed = true;
           state.transfer_message = "Transfer Completed Successfully!";
        },
        [credit_transfer.pending]:(state,action)=>{
            
        },
        [credit_transfer.rejected]:(state,action)=>{
            //console.log(action.payload);
            state.transfer_completed = false;
           state.transfer_message = "Unable to process credit transfer!";
        }

        //reverse
        ,
        [get_trx_history_by_id.fulfilled]:(state,action)=>{
            state.reverse_trx_history_obj = action.payload.data;
            state.reverse_msg = null;
            //console.log(action.payload);
        },
        [get_trx_history_by_id.pending]:(state,action)=>{
            state.reverse_msg = "getting transaction detail ...";
        },
        [get_trx_history_by_id.rejected]:(state,action)=>{
            state.reverse_msg = action.payload;
        }

        ,
        [reverse_trx.fulfilled]:(state,action)=>{
            //console.log(action.payload);
            state.reverse_msg = action.payload?.data?.message;
        },
        [reverse_trx.pending]:(state,action)=>{
            state.reverse_msg = "performing reverse ...";
        },
        [reverse_trx.rejected]:(state,action)=>{
            state.reverse_msg = action.payload;
        }
    }
});

export const {resetTransferMessage} = creditSlice.actions;
const {reducer} = creditSlice;
export default reducer;