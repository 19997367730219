import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Button, Card, TextField, MenuItem } from '@mui/material';

import { Clear, Print, Search } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { get_transactions_by_criteria } from 'src/redux/slices/transactionSlice';
import { getAllUsers } from 'src/redux/slices/userSlice';
import { get_all_companies } from 'src/redux/slices/companySlice';


const SrchInputComp = styled('input')({
  outline: 'none',
  border: '1px solid rgb(192, 192, 192)',
  padding: '10px',
  color: 'rgb(192, 192, 192)',
  borderRadius: '4px',
  '&::placeholder': { color: 'rgb(192, 192, 192)' },
});

function SalesFindPanel() {
  const user = useSelector((state) => state.auth.user);
  const all_users = useSelector((state) => state.auth.all_user_list);
  const companies = useSelector((state) => state.companies.companies);

  const current_date = () => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substring(0, 10);
    return date;
  };

  const [startDate, setStartDate] = useState(current_date());
  const [endDate, setEndDate] = useState(current_date());
  const [cashier, setCashier] = useState(user?.userName);
  const [branch, setBranch] = useState(null);
  const [company, setCompany] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  //when a company is changed show users within that company
  const [selectedCompanyUsers,setSelectedCompanyUsers] = useState([...all_users]);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchUsers() {
      try {
        dispatch(getAllUsers());
      } catch (error) {
        console.log(error);
      }
    }
    if (!all_users || all_users.length == 0) {
      fetchUsers();
    }
  }, []);

  useEffect(() => {
    async function fetchCompanies() {
      try {
        dispatch(get_all_companies());
      } catch (error) {
        console.log(error);
      }
    }
    if (!companies || companies.length == 0) {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    async function selectDefaultComp() {
      if (user?.role != 'super' && user?.role != 'root') {
        const val = await companies?.find((t) => t.company_id === user?.company_id);
        setSelectedCompany(val);
        setCompany(user?.company_id);

        const selectedCompUsers = all_users?.filter((z)=>z.company_id === user?.company_id);
        if(selectedCompUsers)
          setSelectedCompanyUsers(selectedCompUsers);
        else
          setSelectedCompanyUsers([]);

      }
    }
    selectDefaultComp();
  }, [companies]);

  const handleClearClick = () => {
    setStartDate(current_date());
    setEndDate(current_date());
    setBranch(null);
    setCashier(null);
    setCompany(null);
    setSelectedCompany(null);
  };

  const handleFindClick = () => {
    if (endDate && endDate < startDate) {
      alert(`invalid date criteria: end date (${endDate}) is greater than start date`);
      return;
    }

    if (!company || company.length == 0) {
      alert('Please Select Company!');
      return;
    }

    var is_admin = false;
    if (user?.role.toLowerCase() !== 'employee') {
      is_admin = true;
    }
    const criteria = {
      startDate,
      endDate: endDate == '' ? null : endDate,
      cashier: is_admin ? cashier : user?.userName, //cashiers can only see their own transactions
      branch: is_admin ? branch : user?.branch,
      company_id: is_admin ? company : user?.company_id,
    };
    //console.log(criteria);
    //console.log(user.role.toLowerCase());
    dispatch(get_transactions_by_criteria(criteria));
  };

  const handleCompanyChange = (e) => {
    
    const val = companies?.find((t) => t.company_id === e.target.value);
    //console.log(val);
    setSelectedCompany(val);
    setCompany(e.target.value);
    const selectedCompUsers = all_users?.filter((z)=>z.company_id === e.target.value);
    if(selectedCompUsers)
      setSelectedCompanyUsers(selectedCompUsers);
    else
      setSelectedCompanyUsers([]);
  };

  return (
    <Stack spacing={3} direction={{xs:'column',md:'row'}} sx={{padding: '3px', justifyContent: 'space-between' }}>
      <TextField
        onChange={(e) => setStartDate(e.target.value)}
        id="date"
        label="Start Date"
        type="date"
        value={startDate}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        onChange={(e) => setEndDate(e.target.value)}
        id="date"
        label="End Date"
        type="date"
        value={endDate}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        labelId="Company"
        select
        value={(user?.role != 'super' && user?.role != 'root' && user?.role != 'Agent' && user?.role != 'SubAgent') ? user?.company_id : company}
        label="Company"
        onChange={handleCompanyChange}
        fullWidth
      >
        
        {companies?.map((company) => {
          return (
            <MenuItem key={company.id} value={company.company_id}>
              {company.company_name}
            </MenuItem>
          );
        })}
      </TextField>

      <TextField
        labelId="branch"
        select
        value={user?.role == 'Employee' ? user?.branch : branch}
        label="Branch"
        onChange={(e) => setBranch(e.target.value)}
        fullWidth
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {selectedCompany?.branches?.map((branch) => {
          return (
            <MenuItem key={branch.id} value={branch.branch_name}>
              {branch.branch_name}
            </MenuItem>
          );
        })}
      </TextField>

      <TextField
        labelId="cashier"
        select
        value={cashier}
        label="Cashier"
        defaultValue=""
        onChange={(e) => setCashier(e.target.value)}
        fullWidth
      >
        {/* if user role is employee only show current username*/}
        {user?.role != "Employee" && <MenuItem value="">
          <em>None</em>
        </MenuItem>}
        {selectedCompanyUsers?.map((user, index) => {
          return (
            <MenuItem key={user.id} value={user.userName}>
              {user.userName}
            </MenuItem>
          );
        })}
      </TextField>
      <Button variant="contained" endIcon={<Search />} onClick={handleFindClick}>
        Find
      </Button>
      <Button variant="contained" endIcon={<Clear />} onClick={handleClearClick}>
        Clear
      </Button>
      {/*<Button variant="contained" endIcon={<Print />}>
          Print
        </Button> */}
    </Stack>
  );
}

export default SalesFindPanel;
