import React,{useMemo} from 'react';
import { useSelector } from 'react-redux';
import './SelectedPatternDisplay.css'; // For CSS animations

 const patterns = {
    140: [2,7,12,17,22], // Any Vertical (example: first column)
    141: [10,11,12,13,14], // Any Horizontal (example: first row)
    142: [1,6,11,16,21, 3,8,13,18,23], // Any 2 Vertical (first and third columns)
    143: [0,1,2,3,4,,15,16,17,18,19], // Any 2 Horizontal (first and third rows)
    144: [0, 6, 12, 18, 24, 4, 8, 12, 16, 20], // Any Diagonal (both diagonals)
    145: [0, 1, 5, 6], // Any 1 Corner Square (top-left 2x2)
    146: [0, 1, 5, 6, 3, 4, 8, 9], // Any 2 Corner Square (top-left and top-right 2x2)
    147: [0, 1, 5, 6, 3, 4, 8, 9, 15,16,20, 21], // Any 3 Corner Square (top-left, top-right, and bottom-left 2x2)
    148: [0, 1, 5, 6, 3, 4, 8, 9, 15,16,20, 21,18,19,23,24], // All 4 Corner Square (2x2 in each corner)
    149: [0, 1, 2, 3, 4, 10, 11, 12, 13, 14], // Any 2 Lines (first row and middle row)
    150: [0, 1, 2, 3, 4, 10, 11, 12, 13, 14, 20, 21, 22, 23, 24], // Any 3 Lines (first row, middle row, and last row)
    151: [0, 4,20,24], // All 4 Corner Square (Single)
    152: [0, 1, 2, 3, 4,4,8,12,16,20], // Any Line (first row)
    153: [6, 8, 16, 18], // 4 Single Middle (middle cells of each side)
  };
  

  const SelectedPatternDisplay = () => {

    const dynamicPatternOneId = useSelector((state) => state.transactionState.dynamic_pattern_one);
    const dynamicPatternTwoId = useSelector((state) => state.transactionState.dynamic_pattern_two);
  
    const dynamicPatternOne = useMemo(() => patterns[dynamicPatternOneId] || [], [dynamicPatternOneId]);
    const dynamicPatternTwo = useMemo(() => patterns[dynamicPatternTwoId] || [], [dynamicPatternTwoId]);

    // Create a unique key to force re-render when patterns change
    const key = `${dynamicPatternOneId}-${dynamicPatternTwoId}`;
  
    return (
        <div key={key} className="bingo-board">
        {Array(25).fill(null).map((_, index) => {
          const isPatternOne = dynamicPatternOne.includes(index);
          const isPatternTwo = dynamicPatternTwo.includes(index);
  
          let className = 'bingo-cell';
          if (isPatternOne && isPatternTwo) {
            className += ' pattern-both';
          } else if (isPatternOne) {
            className += ' pattern-one';
          } else if (isPatternTwo) {
            className += ' pattern-two';
          }
  
          return (
            <div key={index} className={className}>
              
            </div>
          );
        })}
      </div>
    );
  };

export default SelectedPatternDisplay;
