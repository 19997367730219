import { 
	B1,
  B2,
  B3,
  B4,
  B5,
  B6,
  B7,
  B8,
  B9,
  B10,
  B11,
  B12,
  B13,
  B14,
  B15,
  I16,
  I17,
  I18,
  I19,
  I20,
  I21,
  I22,
  I23,
  I24,
  I25,
  I26,
  I27,
  I28,
  I29,
  I30,
  N31,
  N32,
  N33,
  N34,
  N35,
  N36,
  N37,
  N38,
  N39,
  N40,
  N41,
  N42,
  N43,
  N44,
  N45,
  G46,
  G47,
  G48,
  G49,
  G50,
  G51,
  G52,
  G53,
  G54,
  G55,
  G56,
  G57,
  G58,
  G59,
  G60,
  O61,
  O62,
  O63,
  O64,
  O65,
  O66,
  O67,
  O68,
  O69,
  O70,
  O71,
  O72,
  O73,
  O74,
  O75,
 } from './voices';

 import 
 { 
	TB1,
	TB2,
	TB3,
	TB4,
	TB5,
	TB6,
	TB7,
	TB8,
	TB9,
	TB10,
	TB11,
	TB12,
	TB13,
	TB14,
	TB15,
	TI16,
	TI17,
	TI18,
	TI19,
	TI20,
	TI21,
	TI22,
	TI23,
	TI24,
	TI25,
	TI26,
	TI27,
	TI28,
	TI29,
	TI30,
	TN31,
	TN32,
	TN33,
	TN34,
	TN35,
	TN36,
	TN37,
	TN38,
	TN39,
	TN40,
	TN41,
	TN42,
	TN43,
	TN44,
	TN45,
	TG46,
	TG47,
	TG48,
	TG49,
	TG50,
	TG51,
	TG52,
	TG53,
	TG54,
	TG55,
	TG56,
	TG57,
	TG58,
	TG59,
	TG60,
	TO61,
	TO62,
	TO63,
	TO64,
	TO65,
	TO66,
	TO67,
	TO68,
	TO69,
	TO70,
	TO71,
	TO72,
	TO73,
	TO74,
	TO75,
   } from './voices/tigre';


   import 
   { 
	OB1,
	OB2,
	OB3,
	OB4,
	OB5,
	OB6,
	OB7,
	OB8,
	OB9,
	OB10,
	OB11,
	OB12,
	OB13,
	OB14,
	OB15,
	OI16,
	OI17,
	OI18,
	OI19,
	OI20,
	OI21,
	OI22,
	OI23,
	OI24,
	OI25,
	OI26,
	OI27,
	OI28,
	OI29,
	OI30,
	ON31,
	ON32,
	ON33,
	ON34,
	ON35,
	ON36,
	ON37,
	ON38,
	ON39,
	ON40,
	ON41,
	ON42,
	ON43,
	ON44,
	ON45,
	OG46,
	OG47,
	OG48,
	OG49,
	OG50,
	OG51,
	OG52,
	OG53,
	OG54,
	OG55,
	OG56,
	OG57,
	OG58,
	OG59,
	OG60,
	OO61,
	OO62,
	OO63,
	OO64,
	OO65,
	OO66,
	OO67,
	OO68,
	OO69,
	OO70,
	OO71,
	OO72,
	OO73,
	OO74,
	OO75,
	 } from './voices/oromo';


	 import 
	 { 
	  WB1,
	  WB2,
	  WB3,
	  WB4,
	  WB5,
	  WB6,
	  WB7,
	  WB8,
	  WB9,
	  WB10,
	  WB11,
	  WB12,
	  WB13,
	  WB14,
	  WB15,
	  WI16,
	  WI17,
	  WI18,
	  WI19,
	  WI20,
	  WI21,
	  WI22,
	  WI23,
	  WI24,
	  WI25,
	  WI26,
	  WI27,
	  WI28,
	  WI29,
	  WI30,
	  WN31,
	  WN32,
	  WN33,
	  WN34,
	  WN35,
	  WN36,
	  WN37,
	  WN38,
	  WN39,
	  WN40,
	  WN41,
	  WN42,
	  WN43,
	  WN44,
	  WN45,
	  WG46,
	  WG47,
	  WG48,
	  WG49,
	  WG50,
	  WG51,
	  WG52,
	  WG53,
	  WG54,
	  WG55,
	  WG56,
	  WG57,
	  WG58,
	  WG59,
	  WG60,
	  WO61,
	  WO62,
	  WO63,
	  WO64,
	  WO65,
	  WO66,
	  WO67,
	  WO68,
	  WO69,
	  WO70,
	  WO71,
	  WO72,
	  WO73,
	  WO74,
	  WO75,
	   } from './voices/wolayta';

	   //amharic male
	   import {
		AB1,
		AB2,
		AB3,
		AB4,
		AB5,
		AB6,
		AB7,
		AB8,
		AB9,
		AB10,
		AB11,
		AB12,
		AB13,
		AB14,
		AB15,
		AI16,
		AI17,
		AI18,
		AI19,
		AI20,
		AI21,
		AI22,
		AI23,
		AI24,
		AI25,
		AI26,
		AI27,
		AI28,
		AI29,
		AI30,
		AN31,
		AN32,
		AN33,
		AN34,
		AN35,
		AN36,
		AN37,
		AN38,
		AN39,
		AN40,
		AN41,
		AN42,
		AN43,
		AN44,
		AN45,
		AG46,
		AG47,
		AG48,
		AG49,
		AG50,
		AG51,
		AG52,
		AG53,
		AG54,
		AG55,
		AG56,
		AG57,
		AG58,
		AG59,
		AG60,
		AO61,
		AO62,
		AO63,
		AO64,
		AO65,
		AO66,
		AO67,
		AO68,
		AO69,
		AO70,
		AO71,
		AO72,
		AO73,
		AO74,
		AO75,
	  } from './voices/amharicM'

export function SelectVoice(letter, number) {
  var _combine = letter.toUpperCase() + number;
  let voice;
  switch (_combine) {
    case 'B1':
	voice=B1;
	break;
case 'B2':
	voice=B2;
	break;
case 'B3':
	voice=B3;
	break;
case 'B4':
	voice=B4;
	break;
case 'B5':
	voice=B5;
	break;
case 'B6':
	voice=B6;
	break;
case 'B7':
	voice=B7;
	break;
case 'B8':
	voice=B8;
	break;
case 'B9':
	voice=B9;
	break;
case 'B10':
	voice=B10;
	break;
case 'B11':
	voice=B11;
	break;
case 'B12':
	voice=B12;
	break;
case 'B13':
	voice=B13;
	break;
case 'B14':
	voice=B14;
	break;
case 'B15':
	voice=B15;
	break;
case 'I16':
	voice=I16;
	break;
case 'I17':
	voice=I17;
	break;
case 'I18':
	voice=I18;
	break;
case 'I19':
	voice=I19;
	break;
case 'I20':
	voice=I20;
	break;
case 'I21':
	voice=I21;
	break;
case 'I22':
	voice=I22;
	break;
case 'I23':
	voice=I23;
	break;
case 'I24':
	voice=I24;
	break;
case 'I25':
	voice=I25;
	break;
case 'I26':
	voice=I26;
	break;
case 'I27':
	voice=I27;
	break;
case 'I28':
	voice=I28;
	break;
case 'I29':
	voice=I29;
	break;
case 'I30':
	voice=I30;
	break;
case 'N31':
	voice=N31;
	break;
case 'N32':
	voice=N32;
	break;
case 'N33':
	voice=N33;
	break;
case 'N34':
	voice=N34;
	break;
case 'N35':
	voice=N35;
	break;
case 'N36':
	voice=N36;
	break;
case 'N37':
	voice=N37;
	break;
case 'N38':
	voice=N38;
	break;
case 'N39':
	voice=N39;
	break;
case 'N40':
	voice=N40;
	break;
case 'N41':
	voice=N41;
	break;
case 'N42':
	voice=N42;
	break;
case 'N43':
	voice=N43;
	break;
case 'N44':
	voice=N44;
	break;
case 'N45':
	voice=N45;
	break;
case 'G46':
	voice=G46;
	break;
case 'G47':
	voice=G47;
	break;
case 'G48':
	voice=G48;
	break;
case 'G49':
	voice=G49;
	break;
case 'G50':
	voice=G50;
	break;
case 'G51':
	voice=G51;
	break;
case 'G52':
	voice=G52;
	break;
case 'G53':
	voice=G53;
	break;
case 'G54':
	voice=G54;
	break;
case 'G55':
	voice=G55;
	break;
case 'G56':
	voice=G56;
	break;
case 'G57':
	voice=G57;
	break;
case 'G58':
	voice=G58;
	break;
case 'G59':
	voice=G59;
	break;
case 'G60':
	voice=G60;
	break;
case 'O61':
	voice=O61;
	break;
case 'O62':
	voice=O62;
	break;
case 'O63':
	voice=O63;
	break;
case 'O64':
	voice=O64;
	break;
case 'O65':
	voice=O65;
	break;
case 'O66':
	voice=O66;
	break;
case 'O67':
	voice=O67;
	break;
case 'O68':
	voice=O68;
	break;
case 'O69':
	voice=O69;
	break;
case 'O70':
	voice=O70;
	break;
case 'O71':
	voice=O71;
	break;
case 'O72':
	voice=O72;
	break;
case 'O73':
	voice=O73;
	break;
case 'O74':
	voice=O74;
	break;
case 'O75':
	voice=O75;
	break;
  }
  return voice;
}
export function SelectTigregnaVoice(letter, number) {
	var _combine = letter.toUpperCase() + number;
	let voice;
	switch (_combine) {
	  case 'B1':
	  voice=TB1;
	  break;
  case 'B2':
	  voice=TB2;
	  break;
  case 'B3':
	  voice=TB3;
	  break;
  case 'B4':
	  voice=TB4;
	  break;
  case 'B5':
	  voice=TB5;
	  break;
  case 'B6':
	  voice=TB6;
	  break;
  case 'B7':
	  voice=TB7;
	  break;
  case 'B8':
	  voice=TB8;
	  break;
  case 'B9':
	  voice=TB9;
	  break;
  case 'B10':
	  voice=TB10;
	  break;
  case 'B11':
	  voice=TB11;
	  break;
  case 'B12':
	  voice=TB12;
	  break;
  case 'B13':
	  voice=TB13;
	  break;
  case 'B14':
	  voice=TB14;
	  break;
  case 'B15':
	  voice=TB15;
	  break;
  case 'I16':
	  voice=TI16;
	  break;
  case 'I17':
	  voice=TI17;
	  break;
  case 'I18':
	  voice=TI18;
	  break;
  case 'I19':
	  voice=TI19;
	  break;
  case 'I20':
	  voice=TI20;
	  break;
  case 'I21':
	  voice=TI21;
	  break;
  case 'I22':
	  voice=TI22;
	  break;
  case 'I23':
	  voice=TI23;
	  break;
  case 'I24':
	  voice=TI24;
	  break;
  case 'I25':
	  voice=TI25;
	  break;
  case 'I26':
	  voice=TI26;
	  break;
  case 'I27':
	  voice=TI27;
	  break;
  case 'I28':
	  voice=TI28;
	  break;
  case 'I29':
	  voice=TI29;
	  break;
  case 'I30':
	  voice=TI30;
	  break;
  case 'N31':
	  voice=TN31;
	  break;
  case 'N32':
	  voice=TN32;
	  break;
  case 'N33':
	  voice=TN33;
	  break;
  case 'N34':
	  voice=TN34;
	  break;
  case 'N35':
	  voice=TN35;
	  break;
  case 'N36':
	  voice=TN36;
	  break;
  case 'N37':
	  voice=TN37;
	  break;
  case 'N38':
	  voice=TN38;
	  break;
  case 'N39':
	  voice=TN39;
	  break;
  case 'N40':
	  voice=TN40;
	  break;
  case 'N41':
	  voice=TN41;
	  break;
  case 'N42':
	  voice=TN42;
	  break;
  case 'N43':
	  voice=TN43;
	  break;
  case 'N44':
	  voice=TN44;
	  break;
  case 'N45':
	  voice=TN45;
	  break;
  case 'G46':
	  voice=TG46;
	  break;
  case 'G47':
	  voice=TG47;
	  break;
  case 'G48':
	  voice=TG48;
	  break;
  case 'G49':
	  voice=TG49;
	  break;
  case 'G50':
	  voice=TG50;
	  break;
  case 'G51':
	  voice=TG51;
	  break;
  case 'G52':
	  voice=TG52;
	  break;
  case 'G53':
	  voice=TG53;
	  break;
  case 'G54':
	  voice=TG54;
	  break;
  case 'G55':
	  voice=TG55;
	  break;
  case 'G56':
	  voice=TG56;
	  break;
  case 'G57':
	  voice=TG57;
	  break;
  case 'G58':
	  voice=TG58;
	  break;
  case 'G59':
	  voice=TG59;
	  break;
  case 'G60':
	  voice=TG60;
	  break;
  case 'O61':
	  voice=TO61;
	  break;
  case 'O62':
	  voice=TO62;
	  break;
  case 'O63':
	  voice=TO63;
	  break;
  case 'O64':
	  voice=TO64;
	  break;
  case 'O65':
	  voice=TO65;
	  break;
  case 'O66':
	  voice=TO66;
	  break;
  case 'O67':
	  voice=TO67;
	  break;
  case 'O68':
	  voice=TO68;
	  break;
  case 'O69':
	  voice=TO69;
	  break;
  case 'O70':
	  voice=TO70;
	  break;
  case 'O71':
	  voice=TO71;
	  break;
  case 'O72':
	  voice=TO72;
	  break;
  case 'O73':
	  voice=TO73;
	  break;
  case 'O74':
	  voice=TO74;
	  break;
  case 'O75':
	  voice=TO75;
	  break;
	}
	return voice;
  }
export function SelectOromiffaVoice(letter, number) {
	var _combine = letter.toUpperCase() + number;
	let voice;
	switch (_combine) {
	  case 'B1':
	  voice=OB1;
	  break;
  case 'B2':
	  voice=OB2;
	  break;
  case 'B3':
	  voice=OB3;
	  break;
  case 'B4':
	  voice=OB4;
	  break;
  case 'B5':
	  voice=OB5;
	  break;
  case 'B6':
	  voice=OB6;
	  break;
  case 'B7':
	  voice=OB7;
	  break;
  case 'B8':
	  voice=OB8;
	  break;
  case 'B9':
	  voice=OB9;
	  break;
  case 'B10':
	  voice=OB10;
	  break;
  case 'B11':
	  voice=OB11;
	  break;
  case 'B12':
	  voice=OB12;
	  break;
  case 'B13':
	  voice=OB13;
	  break;
  case 'B14':
	  voice=OB14;
	  break;
  case 'B15':
	  voice=OB15;
	  break;
  case 'I16':
	  voice=OI16;
	  break;
  case 'I17':
	  voice=OI17;
	  break;
  case 'I18':
	  voice=OI18;
	  break;
  case 'I19':
	  voice=OI19;
	  break;
  case 'I20':
	  voice=OI20;
	  break;
  case 'I21':
	  voice=OI21;
	  break;
  case 'I22':
	  voice=OI22;
	  break;
  case 'I23':
	  voice=OI23;
	  break;
  case 'I24':
	  voice=OI24;
	  break;
  case 'I25':
	  voice=OI25;
	  break;
  case 'I26':
	  voice=OI26;
	  break;
  case 'I27':
	  voice=OI27;
	  break;
  case 'I28':
	  voice=OI28;
	  break;
  case 'I29':
	  voice=OI29;
	  break;
  case 'I30':
	  voice=OI30;
	  break;
  case 'N31':
	  voice=ON31;
	  break;
  case 'N32':
	  voice=ON32;
	  break;
  case 'N33':
	  voice=ON33;
	  break;
  case 'N34':
	  voice=ON34;
	  break;
  case 'N35':
	  voice=ON35;
	  break;
  case 'N36':
	  voice=ON36;
	  break;
  case 'N37':
	  voice=ON37;
	  break;
  case 'N38':
	  voice=ON38;
	  break;
  case 'N39':
	  voice=ON39;
	  break;
  case 'N40':
	  voice=ON40;
	  break;
  case 'N41':
	  voice=ON41;
	  break;
  case 'N42':
	  voice=ON42;
	  break;
  case 'N43':
	  voice=ON43;
	  break;
  case 'N44':
	  voice=ON44;
	  break;
  case 'N45':
	  voice=ON45;
	  break;
  case 'G46':
	  voice=OG46;
	  break;
  case 'G47':
	  voice=OG47;
	  break;
  case 'G48':
	  voice=OG48;
	  break;
  case 'G49':
	  voice=OG49;
	  break;
  case 'G50':
	  voice=OG50;
	  break;
  case 'G51':
	  voice=OG51;
	  break;
  case 'G52':
	  voice=OG52;
	  break;
  case 'G53':
	  voice=OG53;
	  break;
  case 'G54':
	  voice=OG54;
	  break;
  case 'G55':
	  voice=OG55;
	  break;
  case 'G56':
	  voice=OG56;
	  break;
  case 'G57':
	  voice=OG57;
	  break;
  case 'G58':
	  voice=OG58;
	  break;
  case 'G59':
	  voice=OG59;
	  break;
  case 'G60':
	  voice=OG60;
	  break;
  case 'O61':
	  voice=OO61;
	  break;
  case 'O62':
	  voice=OO62;
	  break;
  case 'O63':
	  voice=OO63;
	  break;
  case 'O64':
	  voice=OO64;
	  break;
  case 'O65':
	  voice=OO65;
	  break;
  case 'O66':
	  voice=OO66;
	  break;
  case 'O67':
	  voice=OO67;
	  break;
  case 'O68':
	  voice=OO68;
	  break;
  case 'O69':
	  voice=OO69;
	  break;
  case 'O70':
	  voice=OO70;
	  break;
  case 'O71':
	  voice=OO71;
	  break;
  case 'O72':
	  voice=OO72;
	  break;
  case 'O73':
	  voice=OO73;
	  break;
  case 'O74':
	  voice=OO74;
	  break;
  case 'O75':
	  voice=OO75;
	  break;
	}
	return voice;
  }
export function SelectWolaytaVoice(letter, number) {
  var _combine = letter.toUpperCase() + number;
  let voice;

  switch (_combine) {
    case 'B1':
      voice = WB1;
      break;
    case 'B2':
      voice = WB2;
      break;
    case 'B3':
      voice = WB3;
      break;
    case 'B4':
      voice = WB4;
      break;
    case 'B5':
      voice = WB5;
      break;
    case 'B6':
      voice = WB6;
      break;
    case 'B7':
      voice = WB7;
      break;
    case 'B8':
      voice = WB8;
      break;
    case 'B9':
      voice = WB9;
      break;
    case 'B10':
      voice = WB10;
      break;
    case 'B11':
      voice = WB11;
      break;
    case 'B12':
      voice = WB12;
      break;
    case 'B13':
      voice = WB13;
      break;
    case 'B14':
      voice = WB14;
      break;
    case 'B15':
      voice = WB15;
      break;
    case 'I16':
      voice = WI16;
      break;
    case 'I17':
      voice = WI17;
      break;
    case 'I18':
      voice = WI18;
      break;
    case 'I19':
      voice = WI19;
      break;
    case 'I20':
      voice = WI20;
      break;
    case 'I21':
      voice = WI21;
      break;
    case 'I22':
      voice = WI22;
      break;
    case 'I23':
      voice = WI23;
      break;
    case 'I24':
      voice = WI24;
      break;
    case 'I25':
      voice = WI25;
      break;
    case 'I26':
      voice = WI26;
      break;
    case 'I27':
      voice = WI27;
      break;
    case 'I28':
      voice = WI28;
      break;
    case 'I29':
      voice = WI29;
      break;
    case 'I30':
      voice = WI30;
      break;
    case 'N31':
      voice = WN31;
      break;
    case 'N32':
      voice = WN32;
      break;
    case 'N33':
      voice = WN33;
      break;
    case 'N34':
      voice = WN34;
      break;
    case 'N35':
      voice = WN35;
      break;
    case 'N36':
      voice = WN36;
      break;
    case 'N37':
      voice = WN37;
      break;
    case 'N38':
      voice = WN38;
      break;
    case 'N39':
      voice = WN39;
      break;
    case 'N40':
      voice = WN40;
      break;
    case 'N41':
      voice = WN41;
      break;
    case 'N42':
      voice = WN42;
      break;
    case 'N43':
      voice = WN43;
      break;
    case 'N44':
      voice = WN44;
      break;
    case 'N45':
      voice = WN45;
      break;
    case 'G46':
      voice = WG46;
      break;
    case 'G47':
      voice = WG47;
      break;
    case 'G48':
      voice = WG48;
      break;
    case 'G49':
      voice = WG49;
      break;
    case 'G50':
      voice = WG50;
      break;
    case 'G51':
      voice = WG51;
      break;
    case 'G52':
      voice = WG52;
      break;
    case 'G53':
      voice = WG53;
      break;
    case 'G54':
      voice = WG54;
      break;
    case 'G55':
      voice = WG55;
      break;
    case 'G56':
      voice = WG56;
      break;
    case 'G57':
      voice = WG57;
      break;
    case 'G58':
      voice = WG58;
      break;
    case 'G59':
      voice = WG59;
      break;
    case 'G60':
      voice = WG60;
      break;
    case 'O61':
      voice = WO61;
      break;
    case 'O62':
      voice = WO62;
      break;
    case 'O63':
      voice = WO63;
      break;
    case 'O64':
      voice = WO64;
      break;
    case 'O65':
      voice = WO65;
      break;
    case 'O66':
      voice = WO66;
      break;
    case 'O67':
      voice = WO67;
      break;
    case 'O68':
      voice = WO68;
      break;
    case 'O69':
      voice = WO69;
      break;
    case 'O70':
      voice = WO70;
      break;
    case 'O71':
      voice = WO71;
      break;
    case 'O72':
      voice = WO72;
      break;
    case 'O73':
      voice = WO73;
      break;
    case 'O74':
      voice = WO74;
      break;
    case 'O75':
      voice = WO75;
      break;
  }
  return voice;
}

export function SelectAmharicMaleVoice(letter, number) {
	var _combine = letter.toUpperCase() + number;
	let voice;
  
	switch (_combine) {
	  case 'B1':
		voice = AB1;
		break;
	  case 'B2':
		voice = AB2;
		break;
	  case 'B3':
		voice = AB3;
		break;
	  case 'B4':
		voice = AB4;
		break;
	  case 'B5':
		voice = AB5;
		break;
	  case 'B6':
		voice = AB6;
		break;
	  case 'B7':
		voice = AB7;
		break;
	  case 'B8':
		voice = AB8;
		break;
	  case 'B9':
		voice = AB9;
		break;
	  case 'B10':
		voice = AB10;
		break;
	  case 'B11':
		voice = AB11;
		break;
	  case 'B12':
		voice = AB12;
		break;
	  case 'B13':
		voice = AB13;
		break;
	  case 'B14':
		voice = AB14;
		break;
	  case 'B15':
		voice = AB15;
		break;
	  case 'I16':
		voice = AI16;
		break;
	  case 'I17':
		voice = AI17;
		break;
	  case 'I18':
		voice = AI18;
		break;
	  case 'I19':
		voice = AI19;
		break;
	  case 'I20':
		voice = AI20;
		break;
	  case 'I21':
		voice = AI21;
		break;
	  case 'I22':
		voice = AI22;
		break;
	  case 'I23':
		voice = AI23;
		break;
	  case 'I24':
		voice = AI24;
		break;
	  case 'I25':
		voice = AI25;
		break;
	  case 'I26':
		voice = AI26;
		break;
	  case 'I27':
		voice = AI27;
		break;
	  case 'I28':
		voice = AI28;
		break;
	  case 'I29':
		voice = AI29;
		break;
	  case 'I30':
		voice = AI30;
		break;
	  case 'N31':
		voice = AN31;
		break;
	  case 'N32':
		voice = AN32;
		break;
	  case 'N33':
		voice = AN33;
		break;
	  case 'N34':
		voice = AN34;
		break;
	  case 'N35':
		voice = AN35;
		break;
	  case 'N36':
		voice = AN36;
		break;
	  case 'N37':
		voice = AN37;
		break;
	  case 'N38':
		voice = AN38;
		break;
	  case 'N39':
		voice = AN39;
		break;
	  case 'N40':
		voice = AN40;
		break;
	  case 'N41':
		voice = AN41;
		break;
	  case 'N42':
		voice = AN42;
		break;
	  case 'N43':
		voice = AN43;
		break;
	  case 'N44':
		voice = AN44;
		break;
	  case 'N45':
		voice = AN45;
		break;
	  case 'G46':
		voice = AG46;
		break;
	  case 'G47':
		voice = AG47;
		break;
	  case 'G48':
		voice = AG48;
		break;
	  case 'G49':
		voice = AG49;
		break;
	  case 'G50':
		voice = AG50;
		break;
	  case 'G51':
		voice = AG51;
		break;
	  case 'G52':
		voice = AG52;
		break;
	  case 'G53':
		voice = AG53;
		break;
	  case 'G54':
		voice = AG54;
		break;
	  case 'G55':
		voice = AG55;
		break;
	  case 'G56':
		voice = AG56;
		break;
	  case 'G57':
		voice = AG57;
		break;
	  case 'G58':
		voice = AG58;
		break;
	  case 'G59':
		voice = AG59;
		break;
	  case 'G60':
		voice = AG60;
		break;
	  case 'O61':
		voice = AO61;
		break;
	  case 'O62':
		voice = AO62;
		break;
	  case 'O63':
		voice = AO63;
		break;
	  case 'O64':
		voice = AO64;
		break;
	  case 'O65':
		voice = AO65;
		break;
	  case 'O66':
		voice = AO66;
		break;
	  case 'O67':
		voice = AO67;
		break;
	  case 'O68':
		voice = AO68;
		break;
	  case 'O69':
		voice = AO69;
		break;
	  case 'O70':
		voice = AO70;
		break;
	  case 'O71':
		voice = AO71;
		break;
	  case 'O72':
		voice = AO72;
		break;
	  case 'O73':
		voice = AO73;
		break;
	  case 'O74':
		voice = AO74;
		break;
	  case 'O75':
		voice = AO75;
		break;
	}
	return voice;
  }