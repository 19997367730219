import axios from 'axios';

// const API_URL = 'http://localhost:5000/api';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });


const getToken = () => {
  try {
    const token = localStorage.getItem('bingo_access_token');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return config;
  } catch (error) {
    throw error;
  }
};

const login = (userData) => {
  return axiosInstance
    .post('/auth/register_login', userData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      checkStatusAndRefreshPage(err);
      throw err.response.data;
    });
};

const logout = async (dispatch) => {
  
  
  return await dispatch({type: 'auth/logout'});
  
};

const getAllUsers = async () => {
  return await axiosInstance
    .get('/users', getToken())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      checkStatusAndRefreshPage(err);
      //console.log(err);
      throw err.response.data;
    });
};

const getAgentUsers = async () => {
  return await axiosInstance
    .get('/users/agents', getToken())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

const createUser = async (userData) => {
  return await axiosInstance
    .post('/users/', userData, getToken())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      checkStatusAndRefreshPage(err);
      throw err.response.data;
    });
};



const updateUser = async (id, userData) => {
  try {
    
    const response = await axiosInstance.post('/users/update/' + id, userData, getToken());
    
    return response.data;
  } catch (error) {
    console.error('Error occurred during updateUser:', error);
    throw new Error(error.response.data);
  }
};


const getUserInfoByPhone = async(phone) => {
  try {
      const data = await axiosInstance.get('/users/getUserByPhone/'+ phone,getToken());
      return data;
  } catch (error) {
    console.error('Error occurred during updateUser:', error);
      checkStatusAndRefreshPage(error);
      throw error.response.data;
  }
}


const getRoles  = async () =>{
  return await axiosInstance.get('/users/roles',getToken())
  .then((res)=>
  {
    //console.log(res.data);
    return res.data;
  }).catch((err)=>{
    checkStatusAndRefreshPage(error);
    throw err.response.data;
  })
}

const get_user_info = async () => {
  try {
    //console.log('api url from user info');
    return await axiosInstance
      .get('/auth/get_user_info', getToken())
      .then((res) => {
        //console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  } catch (error) {
    checkStatusAndRefreshPage(error);
    throw error;
  }
};

const change_password = async(pwd_change_obj) =>{
  try {
    return await axiosInstance.put('/users/change_pwd',pwd_change_obj,getToken())
    .then((res)=>{
      return res;
    }).catch((err)=>{
      //console.log(err);
      throw err.response;
    });

  } catch (error) {
    checkStatusAndRefreshPage(error);
    //console.log(err);
    throw error;
  }
}

const get_play_percentage = async() =>{
  try {
    
    return await axiosInstance.get('/auth/get_play_percentage_for_user',getToken())
    .then((res)=>{
      return res;
    }).catch((err)=>{
      throw err.response;
    });
  } catch (error) {
    checkStatusAndRefreshPage(error);
    throw error;
  }
}


const get_bet_amount = async() =>{
  try {
    
    return await axiosInstance.get('/auth/get_bet_amount_for_user',getToken())
    .then((res)=>{
      return res;
    }).catch((err)=>{
      throw err.response;
    });
  } catch (error) {
    checkStatusAndRefreshPage(error);
    throw error;
  }
}


const checkStatusAndRefreshPage = async(error)=>{
  try {
      if(error.response.status === 401){
          window.location.reload();
          localStorage.removeItem('bingo_user_info');
          localStorage.removeItem('bingo_access_token');
      }
  } catch (error) {
      
  }
}

const authService = {
  login,
  logout,
  getAllUsers,
  createUser,
  updateUser,
  get_user_info,
  change_password,
  getRoles,
  getAgentUsers,
  getUserInfoByPhone,
  get_play_percentage,
  get_bet_amount
};

export default authService;
