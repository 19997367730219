import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
//import USERLIST from '../_mock/user';

import RegisterForm from '../components/userRegister/RegisterForm';

import { useSelector,useDispatch } from 'react-redux';
import { getAllUsers, getRoles } from 'src/redux/slices/userSlice';
import { get_all_companies, update_company_state} from 'src/redux/slices/companySlice';


const currency_formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'credit', label: 'Balance', alignRight: false },
  { id: 'cut', label: 'Cut', alignRight: false },
  { id: 'betAmount', label: 'Bet', alignRight: false },
  { id: 'company_id', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'branch', label: 'Branch', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  //alert(orderBy);
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  
  const stabilizedThis = array?.map((el, index) => [el, index]);
  
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_user) =>
        _user.company_id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.role.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.phone?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.userName?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function UserPage() {
  
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDialog,setOpenDialog] = useState(false);
  const [USERLIST,setBindData] = useState([]);
  const [roles,setRoles] = useState([]);
  const [selectedUser,setSelectedUser] = useState('');
  const [renderModal,setRenderModal] =useState(false);

  const [phoneEditLock,setPhoneEditLock] = useState(false);

  const user_lists = useSelector(state=>state.auth.all_user_list);
  const role_list = useSelector(state=>state.auth.roles);
  const current_user = useSelector(state=>state.auth.user);

  const [userToEdit,setEditUser] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchUsers(){
      try {
        dispatch(getAllUsers());
      } catch (error) {
        console.log(error)
      }
    }
    fetchUsers();
  },[]);


  useEffect(()=>{
    function prepareData(){
     try {
      setBindData(
        user_lists
          ?.filter((t) => {
            if (current_user?.role !== 'super') {
              return t.role !== current_user?.role && !t.userName.includes('.sales');
            }
            return true;
          })
          .map((data, index) => ({
            
            id: data._id,
            avatarUrl: `/assets/images/avatars/avatar_${Math.floor(Math.random() * (24 - 1 + 1)) + 1}.jpg`,
            name: data.fullName,
            userName:data.userName,
            company_id: data.company_id,
            isVerified: data.isActive,
            status: data.isActive ? 'active' : 'banned',
            role: data.role,
            branch: data.branch,
            phone: data.phone,
            credit: data.credit,
            cut: data.cut,
            betAmount:data.betAmount
          }))
      );
     } catch (error) {
      console.log(error)
     }
    };
    prepareData();
  },[user_lists]);

  useEffect(()=>{
    if(role_list.length == 0){
      dispatch(getRoles());
    }
  },[role_list]);

  useEffect(()=>{
    async function fetchCompanies(){
      try {
        dispatch(get_all_companies());
      } catch (error) {
        console.log(error)
      }
    }
    fetchCompanies();
  },[]);

  const handleDialogOpen = () => {
    setEditUser(null);
    setOpenDialog(true);
    setRoles(role_list);
    setRenderModal(true);
    setPhoneEditLock(false);
  }

  const handleDialogClose = ()=>{
    setOpenDialog(false);
  }

  const handleOpenMenu = (event,key) => {
    setOpen(event.currentTarget);
    setSelectedUser(key);
    
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedUser(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers?.length && !!filterName;

  //update user information
  const handleEdit = () =>{
    setRenderModal(true);
    setRoles(role_list);
    var selected_user_detail = user_lists.find(t=>t._id === selectedUser);
    if(selected_user_detail){
      //console.log(selected_user_detail);
      if(selected_user_detail.phone && selected_user_detail.phone.length == 10 && current_user.role !== 'super'){
        setPhoneEditLock(true);
      }else{
        setPhoneEditLock(false);
      }
      setEditUser(selected_user_detail);
      setOpenDialog(true);
    }else{
      //alert('user not found')
    }
  }

  //set user active inactive
  const update_user = (is_active) => {
    setRenderModal(true);
    const user_info = {
      _id : selectedUser,
      isActive : is_active
    };
    dispatch(update_company_state(comp_info));
    setOpen(null);
  }

  return (
    <>
      <Helmet>
        <title> User | ሃገሬ ጌምስ </title>
      </Helmet>

      {renderModal && <RegisterForm openDialog={openDialog} handleDialogClose={handleDialogClose} roles={roles} user = {userToEdit} phoneEditLock={phoneEditLock}/>}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>} onClick={handleDialogOpen} >
            New User
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} filter_place_holder={"Search"} isSales={false}/>
          

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name,userName,phone,credit,cut,betAmount, role,branch, status, company_id, avatarUrl, isVerified } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{userName}</TableCell>
                        <TableCell align="left">{phone}</TableCell>
                        <TableCell
                          align="left"
                          style={
                            credit < 50 ? { color: 'red', fontWeight: 'bold' } : { color: 'green', fontWeight: 'bold' }
                          }
                        >
                          {credit ? currency_formatter.format(credit) : 0}
                        </TableCell>
                        <TableCell align="left">{cut > 0 ? `${cut}%` : '0'}</TableCell>
                        <TableCell alignt="left">{betAmount}</TableCell>
                        <TableCell align="left">{company_id}</TableCell>

                        <TableCell align="left">{role}</TableCell>
                        <TableCell align="left">{branch}</TableCell>

                        <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>

                        <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem  onClick={handleEdit}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }}/>
          Edit
        </MenuItem>

        {/* <MenuItem onClick={()=>update_user(true)}>
          <Iconify icon={'eva:activity-outline'} sx={{ mr: 2 }}/>
          Activate
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={()=>update_user(false)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Deactivate
        </MenuItem> */}
      </Popover>
    </>
  );
}
