
import { configureStore,combineReducers } from "@reduxjs/toolkit";
import authReducer from './slices/userSlice';
import boardReducer from './slices/boardSlice';
import  appStateReducer  from "./slices/appStateSlice";
import transactionReducer from './slices/gamePlaySlice';
import transactionListReducer from './slices/transactionSlice';
import companyReducer from './slices/companySlice';
import creditReducer from './slices/creditSlice';

const reducer = combineReducers({
    auth:authReducer,
    appState:appStateReducer,
    transactionState:transactionReducer,
    transactionList:transactionListReducer,
    companies:companyReducer,
    boards:boardReducer,
    credits:creditReducer,
});

const reducerProxy = (state,action)=>{
  if(action.type === 'auth/logout'){
    localStorage.removeItem('bingo_user_info');
    localStorage.removeItem('bingo_access_token');
    return reducer(undefined,action);
  }
  return reducer(state,action);
}

export const store = configureStore({
    reducer:reducerProxy,//reducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

//add root reducer here