// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
    is_admin_only:true,
  },
  {
    title: 'sales',
    path: '/dashboard/sales',
    icon: icon('ic_sales'),
    is_admin_only:false,
    is_sales:true
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    is_admin_only:true,
  },
  {
    title: 'bingo play',
    path: '/dashboard/bingo',
    icon: icon('ic_cart'),
    is_admin_only:false,
    is_sales:false,
    is_emp_only:true,
  },
  {
    title: 'cartela',
    path: '/dashboard/cartela',
    icon: icon('ic_cartela'),
    is_admin_only:true, 
  },
  {
    title: 'companies',
    path: '/dashboard/companies',
    icon: icon('ic_company'),
    is_agent_only:false,
    is_super_only:true,
    is_admin_only:true
  },
  {
    title: 'Credit Balance',
    path: '/dashboard/credit',
    icon: icon('credit_balance'),
    is_admin_only:false,
    is_sales:false,
    is_credit:true,
  },

];

export default navConfig;
