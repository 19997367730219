// /*
//  *  Pattern Class
//  *  Karol Brennan
//  *  4.19.2020
//  *  This class is used to display and control the game pattern.
//  */
// import React from 'react';
// import { useSelector } from 'react-redux';


// export default function Pattern({ pattern, update }) {
//   /*
//    *  Render Pattern Function
//    *  This will display a bingo card where the user can create their own pattern
//    *  Or choose a pattern from the searchable drop down
//    */
//   //const selected_board_object = useSelector(state=>state.boards.selected_board);
//   const winning_info = useSelector((state) => state.boards.winning_info);

//   const selected_board_object = winning_info?.board;


 

  

//   console.log('------------winning info is ------------------');
//   console.log(winning_info);
//   console.log(pattern);
//   console.log('------------end winning info  ------------------');

//   let arr = [
//     [0, 0, 0, 0, 0],
//     [0, 0, 0, 0, 0],
//     [0, 0, 0, 0, 0],
//     [0, 0, 0, 0, 0],
//     [0, 0, 0, 0, 0],
//   ];
//   if (selected_board_object && selected_board_object.length > 0) {
//     arr = selected_board_object;
//   } else {
//     console.log('else object is: ');
//     console.log(selected_board_object);
//     console.log('end else object is: ');
//   }

//   const patterns = JSON.parse(JSON.stringify(pattern.pattern));

//   return (
//     <div  id="bingopattern" className="notranslate">
//       {Object.keys(patterns).map((letter, out_index) => {
//         return (
//           <div key={letter + out_index} className="row vertical-row text-center">
//             <div className="col dark-bg white-text">
//               <span>{letter}</span>
//             </div>
//             {Object.keys(patterns[letter]).map((number, in_index) => {
//               if (winning_info?.is_winner) {
//                 return (
//                   <div
//                     key={letter + number}
//                     className={
//                       winning_info?.winning_pattern?.includes(arr[out_index][in_index]) ? 'selected col' : 'col'
//                     }
//                   >
//                     {letter === 'N' && in_index === 2 ? (
//                       <span style={{ color: 'red', fontWeight: 'bolder', fontSize: '15px' }} className="free-space">
//                         {arr[out_index][in_index]}
//                       </span>
//                     ) : (
//                       <span style={{ fontWeight: 'bold' }}>{arr[out_index][in_index]}</span>
//                     )}
//                   </div>
//                 );
//               } else if (winning_info && !winning_info?.is_winner) {
//                 //if looser bind all call list with red
//                 return (
//                   <div
//                     key={letter + number}
//                     className={winning_info?.call_list?.includes(arr[out_index][in_index]) ? 'looser col' : 'col'}
//                   >
//                     {letter === 'N' && in_index === 2 ? (
//                       <span style={{ color: 'red', fontWeight: 'bolder', fontSize: '15px' }} className="free-space">
//                         {arr[out_index][in_index]}
//                       </span>
//                     ) : (
//                       <span style={{ fontWeight: 'bold' }}>{arr[out_index][in_index]}</span>
//                     )}
//                   </div>
//                 );
//               } else {
//                 return (
//                   <div key={letter + number} className="col">
//                     {letter === 'N' && in_index === 2 ? (
//                       <span style={{ color: 'red', fontWeight: 'bolder', fontSize: '15px' }} className="free-space">
//                         {arr[out_index][in_index]}
//                       </span>
//                     ) : (
//                       <span style={{ fontWeight: 'bold' }}>{arr[out_index][in_index]}</span>
//                     )}
//                   </div>
//                 );
//               }
//             })}
//           </div>
//         );
//       })}
//     </div>
//   );
// }



/*
 *  Pattern Class
 *  Karol Brennan
 *  4.19.2020
 *  This class is used to display and control the game pattern.
 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { set_selected_pattern } from 'src/redux/slices/transactionSlice';

const Pattern = (props) => {


  const [selectedPattern, setSelectedPattern] = useState(props.pattern.pattern);
  const dispatch = useDispatch();

  const handlePatternUpdate = (letter, index,rowIndex,colIndex) => {

    const updatedPattern = {
      ...selectedPattern,
      [letter]: {
        ...selectedPattern[letter],
        [index]: !selectedPattern[letter][index]
      }
    };
    dispatch(set_selected_pattern(updatedPattern));
    setSelectedPattern(updatedPattern);
  };

  return (
    <div id="bingopattern" className="notranslate">
      {Object.keys(selectedPattern).map((letter, rowIndex) => {
        return (
          <div key={letter + rowIndex} className="row vertical-row text-center">
            <div className="col dark-bg white-text"><span>{letter}</span></div>
            {Object.keys(selectedPattern[letter]).map((number, colIndex) => {
              const isSelected = selectedPattern[letter][number];
              const classNames = isSelected ? 'selected col' : 'col';
              return (
                <div
                  key={letter + number}
                  className={classNames}
                  onClick={(e) => handlePatternUpdate(letter, number,rowIndex,colIndex)}
                >
                  {letter === 'N' && colIndex === 2 ? (
                    <span className="free-space">Free Space</span>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Pattern;





