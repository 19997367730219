import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
//import USERLIST from '../_mock/user';

import { useSelector,useDispatch } from 'react-redux';
import { get_all_companies, update_company_state,delete_company_info} from 'src/redux/slices/companySlice';
import CompanyRegisterForm from 'src/components/userRegister/CompnayRegisterForm';

import NotificationModal from 'src/components/bingo/NotificationModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'company_id', label: 'Company Id', alignRight: false },
  { id: 'company_name', label: 'Company Name', alignRight: false },
  { id: 'credit_amount', label: 'Credit', alignRight: false },
  { id: 'agent', label: 'Agent', alignRight: false },
  { id: 'subAgent', label: 'Sub-Agent', alignRight: false },
  { id: 'expiry_date', label: 'Expiry Date', alignRight: false },
  { id: 'remainingDate', label: 'Remaining Days', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'expired', label: 'Expired', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.company_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const currency_formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function Companies() {

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [openDialog,setOpenDialog] = useState(false);

  const [COMPANY_LIST,setBindData] = useState([]);

  const [selectedCompany,setSelectedCompany] = useState('');

  const [companyToEdit,setEditCompany] = useState(null);

  const [renderModal,setRenderModal] =useState(false);

  const [openNotificationDialog,setOpenNotificationDialog] = useState(false);
  const [renderNotificationModal,setRenderNotificationModal] =useState(false);

  const companies_lists = useSelector(state=>state.companies.companies);
  const loged_in_user = useSelector(state => state.auth.user);;
  
  const dispatch = useDispatch();

  useEffect(()=>{
    async function fetchCompanies(){
      try {
        dispatch(get_all_companies());
      } catch (error) {
        console.log(error)
      }
    }
    fetchCompanies();
  },[]);


  useEffect(() => {
    function prepareData() {
      const currentDate = new Date();
      try {
        setBindData(
          companies_lists?.map((data, index) => ({
            id: data.id,
            company_id: data.company_id,
            company_name: data.company_name,
            credit_amount: data.credit_amount,
            agent: data.agent,
            expiry_date: (data.expiry_date && data.expiry_date.length > 0) ? new Date(data.expiry_date).toISOString().split("T")[0] : null,
            status: data.isActive ? 'active' : 'banned',
            remainingDays: data.expiry_date && data.expiry_date.length > 0
              ? Math.floor((new Date(data.expiry_date) - currentDate) / (24 * 60 * 60 * 1000))
              : '',
            expired: (data.expiry_date && data.expiry_date.length > 0) ? (new Date(data.expiry_date).toISOString().split("T")[0] < new Date().toISOString().split("T")[0] ? 'expired' : 'active') : 'active'
            ,
            branches: data.branches,
            subAgent:data.subAgent
          }))
        );
      } catch (error) {
        console.log(error)
      }
        
      
    };
    prepareData();
  },[companies_lists]);

  const handleDialogOpen = () => {
    setEditCompany(null);
    setOpenDialog(true);
    setRenderModal(true);
  }

  const handleEdit = () =>{
    setRenderModal(true);
    var selected_company_detail = companies_lists.find(t=>t.company_id === selectedCompany);
    if(selected_company_detail){
      setEditCompany(selected_company_detail);
      setOpenDialog(true);
    }
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  const handleNotificationDialogOpen = () => {
    setEditCompany(null);
    setOpenNotificationDialog(true);
    setRenderNotificationModal(true);
  }

  const handleSendNotifications = () => {
    setRenderNotificationModal(true);
    var selected_company_detail = companies_lists.find(t=>t.company_id === selectedCompany);
    if(selected_company_detail){
      setEditCompany(selected_company_detail);
      setOpenNotificationDialog(true);
    }
  }

  const handleNotificationDialogClose = () =>{
    
    setOpenNotificationDialog(false);
  }


 

  const handleOpenMenu = (event,key) => {
    setOpen(event.currentTarget);
    setSelectedCompany(key);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = COMPANY_LIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - COMPANY_LIST.length) : 0;

  const filteredCompanies = applySortFilter(COMPANY_LIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredCompanies.length && !!filterName;

  const update_company = (is_active) => {

    setRenderModal(true);
    const comp_info = {
      company_id : selectedCompany,
      isActive : is_active
    };
    dispatch(update_company_state(comp_info));
    setOpen(null);
  }

  const delete_company = () => {
    setRenderModal(true);
    const comp_info = {
      company_id : selectedCompany
    };
    dispatch(delete_company_info(comp_info));
    setOpen(null);
  }


  return (
    <>
      <Helmet>
        <title> Companies | ሃገሬ ጌምስ </title>
      </Helmet>
      {renderModal && (
        <CompanyRegisterForm openDialog={openDialog} handleDialogClose={handleDialogClose} company={companyToEdit} />
      )}

      {renderNotificationModal && (
        <NotificationModal
          open={openNotificationDialog}
          handleNotificationDialogClose={handleNotificationDialogClose}
          company={companyToEdit?.company_id}
        />
      )}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Companies
          </Typography>
          {loged_in_user?.role === 'super' && (
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleDialogOpen}>
              New Company
            </Button>
          )}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filter_place_holder={'Search Company Name'}
            isSales={false}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={COMPANY_LIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredCompanies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      company_id,
                      company_name,
                      credit_amount,
                      agent,
                      subAgent,
                      expiry_date,
                      remainingDays,
                      status,
                      expired,
                    } = row;
                    const selectedCompany = selected.indexOf(company_id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedCompany}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedCompany} onChange={(event) => handleClick(event, company_id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {company_id}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{company_name}</TableCell>

                        <TableCell align="left">{currency_formatter.format(credit_amount)}</TableCell>
                        <TableCell align="left">{agent}</TableCell>
                        <TableCell align="left">{subAgent}</TableCell>
                        <TableCell align="left">{expiry_date}</TableCell>
                        <TableCell align="left">{remainingDays}</TableCell>

                        <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>

                        <TableCell align="left">
                          <Label color={(expired === 'expired' && 'error') || 'success'}>{sentenceCase(expired)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, company_id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={COMPANY_LIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {(loged_in_user?.role === 'super' || loged_in_user?.role === 'Agent') && (
          <MenuItem onClick={handleEdit}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
        )}

        <MenuItem onClick={() => handleSendNotifications()}>
          <Iconify icon={'ic:outline-email'} sx={{ mr: 2 }} />
          Message
        </MenuItem>

        <MenuItem onClick={() => update_company(true)}>
          <Iconify icon={'eva:activity-outline'} sx={{ mr: 2 }} />
          Activate
        </MenuItem>
        {loged_in_user?.role === 'super' && (
          <>
            <MenuItem sx={{ color: 'error.main' }} onClick={() => update_company(false)}>
              <Iconify icon={'ri:shut-down-line'} sx={{ mr: 2 }} />
              Deactivate
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }} onClick={() => delete_company(false)}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}
